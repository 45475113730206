
import { defineComponent } from "vue";
import { parseInstructionType, parseInstructionStatus } from "@/utils/tool";
import { receiveInstruction } from "@/api/task";

export default defineComponent({
  props: {
    item: {
      type: Object,
      value: () => ({})
    },
  },
  setup(props: any, ctx) {
    const {
      instructionType,
      instructStatus,
      instructionTitle,
      sendTime,
      status,
      receiveId,
    } = props.item;

    const parsedInstructionType = parseInstructionType(instructionType);

    const parsedInstructionStatus = parseInstructionStatus(instructStatus);

    const clickCardItem = async () => {
      // 判断接收状态 未接收改为已接收
      if (status === "1") {
        await receiveInstruction({ receiveId });
      }

      // 跳转到详情页
      ctx.emit("clickItem", {
        instructionId: props.item.instructionId,
        receiveId: props.item.receiveId,
      });
    };

    return {
      instructionTitle,
      sendTime,
      parsedInstructionType,
      parsedInstructionStatus,
      clickCardItem,
    };
  },
});
