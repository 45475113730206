
import TaskItem from "@/components/TaskItem.vue";
import { reactive, defineComponent, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getTaskList } from "@/api/task";
import dayjs from "dayjs";
import { useStore } from "vuex";
import { authLogin } from '@/utils/auth';

enum InstructionStatus {
  PENDING = "1",
  SUCCESS = "2",
  EXPIRED = "3",
}

enum InstrcutionType {
  FORWARD = 1,
  READ = 4,
  COMMENT = 5,
  LIKE = 6,
}

export default defineComponent({
  components: { TaskItem },

  beforeRouteEnter(to: any, _from: any, next: any) {
    authLogin(to).then(next)
  },

  setup() {
    const store = useStore();
    // console.log('store', store)
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false,
      list: [], // 列表数据
      statusOpts: [
        { text: "全部状态", value: "" },
        { text: "待完成", value: InstructionStatus.PENDING },
        { text: "已完成", value: InstructionStatus.SUCCESS },
        { text: "已过期", value: InstructionStatus.EXPIRED },
      ],
      typeOpts: [
        { text: "全部类型", value: "" },
        { text: "转发", value: InstrcutionType.FORWARD },
        { text: "阅读", value: InstrcutionType.READ },
        { text: "评论", value: InstrcutionType.COMMENT },
        { text: "点赞", value: InstrcutionType.LIKE },
      ],
      // 按照月份筛选
      timeOpts: [{ text: "全部时间", value: "" }],
    });

    const pageSize = {
      page: 1,
      limit: 10,
    };

    const searchVal = reactive({
      instructStatus: "",
      instructionType: "",
      searchTime: "",
    });

    const parseTimeToRangeTime = (time: string) => {
      if (!time) {
        return {};
      }
      let v = +time;
      return {
        beginTime: dayjs()
          .month(v)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        endTime: dayjs().month(v).endOf("month").format("YYYY-MM-DD HH:mm:ss"),
      };
    };

    const resetPage = () => {
      pageSize.page = 1;
      state.list = [];
    };

    let searchParams = {};
    // 监听搜索条件变化重新加载数据
    watch(searchVal, function () {
      // 将月份处理为时间范围 beginTime endTime
      const { instructStatus, instructionType, searchTime } = searchVal;
      const timeRange = searchTime && parseTimeToRangeTime(searchTime);
      searchParams = {
        instructStatus,
        instructionType,
        ...timeRange,
        // parseTimeToRangeTime:
      };
      resetPage();
      onLoad();
    });

    const onLoad = async () => {
      const res = await getTaskList({ ...pageSize, ...searchParams });
      state.loading = false;
      state.refreshing = false;
      if ((res as any).resp_code === 0) {
        const { current, pages, records } = (res as any).datas;
        state.list = state.list.concat(records) || [];
        state.finished = current >= pages;
        state.finished || pageSize.page++;
      } else {
        state.finished = true;
      }
    };

    const onRefresh = () => {
      resetPage();
      if (state.finished) {
        state.finished = false;
      } else {
        onLoad();
      }
    };

    const router = useRouter();
    const clickTaskItem = (v: any) => {
      router.push({
        name: "taskDetail",
        query: v,
      });
    };

    // 创建时间选择范围 1 - 12 月 从0开始
    const createTimeOpts = () => {
      let num = 12;
      let arr = [];
      for (let i = 0; i < num; i++) {
        let obj = { text: `${i + 1}月`, value: "" + i };
        arr.push(obj);
      }
      state.timeOpts = state.timeOpts.concat(arr);
    };

    onMounted(() => {
      store.commit("updateName", "task");
      createTimeOpts();
    });

    return {
      state,
      searchVal,
      onRefresh,
      onLoad,
      clickTaskItem,
    };
  },
});
